
/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//评论相关
const sourceSubject = {
    name: 'sourceSubject',
    apis: {
        /**
         * 从爬的数据转到真实的表中。
         * @param params
         * @returns {*}
         */
        sourceSubjectList(params) {
            return httpFetch('post', '/api/sourceSubject/sourceSubjectList', params);
        },
        /**
         * 读取远程json
         * @param params
         * @returns {Promise<T>}
         */
        loadJsonFromFile(params){
            return httpFetch('post', `/api/sourceSubject/loadJsonFromFile`,params);
        },
        /**
         * 获取可供绑定的题目
         * @param params
         * @returns {*}
         */
        bindSubjectData(params) {
            return httpFetch('post', '/api/sourceSubject/bindSubjectData', params);
        },
        /**
         * 自动绑定
         * @param params
         * @returns {Promise<*>}
         */
        autoBind(params){
            return httpFetch('post', `/api/sourceSubject/autoBind`,params);
        },
        toShunxu(params){
            return httpFetch('post', `/api/sourceSubject/toShunxu`,params);
        },
        bindData(params) {
            return httpFetch('post', '/api/sourceSubject/bindData', params);
        },
        sourceSubjectMark(params) {
            return httpFetch('post', '/api/sourceSubject/sourceSubjectMark', params);
        },
    }
};
export default sourceSubject;
